import styles from "../css/spark-override.cssx";


const loadjscssfile = (filename, filetype) => {
	if (filetype == "js") { //if filename is a external JavaScript file
		let fileref = document.createElement('script')
		fileref.setAttribute("type", "text/javascript")
		fileref.setAttribute("src", filename)
	}
	else if (filetype == "css") { //if filename is an external CSS file
		let fileref = document.createElement("link")
		fileref.setAttribute("rel", "stylesheet")
		fileref.setAttribute("type", "text/css")
		fileref.setAttribute("href", filename)
	}
	if (typeof fileref != "undefined")
		document.getElementsByTagName("head")[0].appendChild(fileref)
}

const modifyImagesPath = (response, url) => {
	let modifiedResponse = response.replaceAll("href=\"images/", `href=\"${url}/images/`).replaceAll("style=\"background-image: url(images/", `style=\"background-image: url(${url}/images/`);

	return modifiedResponse;
}


const appendStyles = (styles) => {

	/* Create style element */
	let css = document.createElement('style');

	if (css.styleSheet)
		css.styleSheet.cssText = styles;
	else
		css.appendChild(document.createTextNode(styles));

	/* Append style to the head element */
	document.getElementsByTagName("head")[0].appendChild(css);
}

const removeAdobeSparkPageBranding = (adobeSparkUrl) => {
	fetch(adobeSparkUrl).then(
		(response) => {
			return response.text();
		}
	).then((response) => {
		let modifiedResponse = modifyImagesPath(response, adobeSparkUrl);
		document.open();
		document.write(modifiedResponse);
		document.close();
		appendStyles(styles);
	})
}


export default removeAdobeSparkPageBranding;
